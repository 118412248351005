import React from "react";
import { Link } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import "../styles/PostCard.css";

// Utility function to create a URL-friendly string from the category name
const createUrlFromName = (name) => {
  return name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]+/g, "");
};

const PostCard = ({
  slug,
  title,
  description,
  image,
  alt,
  categories, // categories are expected to be an array of objects with title and URL
  post_date,
  author,
}) => {
  return (
    <div className="post-card">
      <img src={image} alt={alt} className="post-image" />
      <div className="post-info">
        <h2>{title}</h2>
        <div className="post-categories">
          {categories?.map((category, index) => (
            <React.Fragment key={category._id}>
              <Link
                to={`/category/${createUrlFromName(category.title)}`} // Use the category name in the URL
                className="post-category post-category-link"
              >
                {category.title}
              </Link>
              {index < categories.length - 1 && ", "}
            </React.Fragment>
          ))}
        </div>
        <div className="post-description">
          {Array.isArray(description) ? (
            <PortableText value={description} />
          ) : (
            <p>{description}</p> // Fallback for plain text descriptions
          )}
        </div>
        <p className="post-author">By {author}</p>
        <p className="post-date">{post_date}</p>
        <Link to={`/post/${slug}`} className="read-more-button">
          Read More
        </Link>
      </div>
    </div>
  );
};

export default PostCard;
