import React, { useState } from "react";
import { Helmet } from "react-helmet";
import emailjs from "emailjs-com"; // Import the emailjs-com library
import "../styles/Contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const btn = document.getElementById("button");
    btn.value = "Sending...";

    const serviceID = "service_az48nhr"; // Replace with your actual service ID
    const templateID = "template_63q936h"; // Replace with your actual template ID
    const userID = "dgVpahMzgK_RNmJUl"; // Replace with your actual user ID from EmailJS

    emailjs.sendForm(serviceID, templateID, event.target, userID).then(
      () => {
        btn.value = "Send Email";
        alert("Sent!");
        setFormData({ name: "", email: "", message: "" }); // Clear the form
      },
      (err) => {
        btn.value = "Send Email";
        alert(JSON.stringify(err));
      }
    );
  };

  return (
    <>
      <Helmet>
        <title>Contact Me</title>
        <meta
          name="description"
          content="Contact page to reach out to me for queries or feedback."
        />
        <meta name="keywords" content="contact, feedback, queries" />
        <meta property="og:title" content="Contact Me" />
        <meta
          property="og:description"
          content="Reach out to me with your queries or feedback through this contact form."
        />
        <meta property="og:image" content="URL_to_image_for_social_media.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://iuriistorozhenko.com/contact" />
      </Helmet>
      <div className="main-container">
        <h1>Contact Me</h1>
        <form onSubmit={handleSubmit} id="form" className="contact-form">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <input type="submit" id="button" value="Send Email" />
        </form>
      </div>
    </>
  );
}

export default Contact;
