import React from "react";
import "../styles/Home.css";
import PostList from "../components/PostList";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Search from "../components/Search";
import Categories from "../components/Categories";

const Home = () => {
  // Static SEO Data
  const seoData = {
    seoTitle: "Personal website and blog",
    metaDescription:
      "On this webpage you will find information related ot technology, AI, engineering, recommendation for book reading",
    openGraphImage: {
      asset: {
        url: "%PUBLIC_URL%/logo192.png",
      },
      alt: "",
    },
    canonicalUrl: "https://iuriistorozhenko.com/",
    structuredData: {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: "Iurii Storozhenko' website",
      url: "https://iuriistorozhenko.com/",
    },
  };

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{seoData.seoTitle}</title>
          <meta name="description" content={seoData.metaDescription} />
          <meta property="og:title" content={seoData.seoTitle} />
          <meta property="og:description" content={seoData.metaDescription} />
          <meta
            property="og:image"
            content={seoData.openGraphImage.asset.url}
          />
          <meta property="og:image:alt" content={seoData.openGraphImage.alt} />
          <link rel="canonical" href={seoData.canonicalUrl} />
          {seoData.structuredData && (
            <script type="application/ld+json">
              {JSON.stringify(seoData.structuredData)}
            </script>
          )}
        </Helmet>
        <div className="main-container">
          <main id="main_info">
            <h1>
              "The only way to do great work is to love what you do. If you
              haven’t found it yet, keep looking. Don’t settle."
              <br />
              Steve Jobs.
            </h1>
          </main>
          <Search />
          <Categories />
          <PostList />
        </div>
      </HelmetProvider>
    </>
  );
};

export default Home;
