import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Header.css"; // Ensure this path is correct

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  /*   const handleLogin = () => {
    const studioUrl = process.env.REACT_APP_SANITY_STUDIO_URL;
    if (!studioUrl) {
      console.error(
        "Sanity Studio URL is not defined in the environment variables"
      );
      return;
    }
    window.location.href = studioUrl;
  }; */

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header-container">
      <div className="hamburger" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <nav className={`nav-menu ${isMenuOpen ? "open" : ""}`}>
        <Link to="/" className="nav-link" onClick={() => setIsMenuOpen(false)}>
          Home
        </Link>
        <Link
          to="/i_am"
          className="nav-link"
          onClick={() => setIsMenuOpen(false)}
        >
          I am
        </Link>
        <Link
          to="/i_do"
          className="nav-link"
          onClick={() => setIsMenuOpen(false)}
        >
          I do
        </Link>
        <Link
          to="/i_travel"
          className="nav-link"
          onClick={() => setIsMenuOpen(false)}
        >
          I travel
        </Link>
        <Link
          to="/i_read"
          className="nav-link"
          onClick={() => setIsMenuOpen(false)}
        >
          I read
        </Link>
        <Link
          to="/contact"
          className="nav-link"
          onClick={() => setIsMenuOpen(false)}
        >
          Contact me
        </Link>
      </nav>
      {/*       <button className="login-button" onClick={handleLogin}>
        Log-in
      </button> */}
    </header>
  );
};

export default Header;
