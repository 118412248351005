import React from "react";
import { Helmet } from "react-helmet";
import "../styles/I_am.css";

function I_am() {
  return (
    <>
      <Helmet>
        <title>I am</title>
        <meta name="description" content="This page contains my resume (CV)" />
        <meta name="keywords" content="resume, CV" />
        <meta property="og:title" content="I am" />
        <meta
          property="og:description"
          content="This page contains my resume (CV)"
        />
        <meta property="og:image" content="URL_to_image_for_social_media.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://iuriistorozhenko.com/i_am" />
      </Helmet>
      <div className="main-container">
        <main id="main_info">
          <h1>
            "An investment in knowledge <br />
            pays the best interest."
            <br />
            Benjamin Franklin.
          </h1>
        </main>

        <section id="right-section">
          <div id="summary">
            <h1>Summary</h1>
            <hr />
            <p>
              Hello, I'm a Ph.D. candidate in Mechanical and Electrical
              Engineering with a passion for renewable energy. My research
              focuses on mathematical modeling, fault diagnostics, and health
              condition monitoring of wind turbine components and subsystems.
              Currently pursuing my Ph.D. in Mechanical Engineering at the
              University of Calgary, I previously worked as the Deputy Chief
              Power Electrical Engineer at Zapolyarnaya building company. This
              role allowed me to gain practical experience in electrical power
              engineering, which has greatly enhanced my technical skills and
              competencies.
            </p>
            <p>
              My expertise lies in a variety of areas, including renewable
              energy wind/solar, health condition monitoring, electrical power
              supply of industrial enterprises, vibration analysis, motor
              current signature analysis, advanced signal processing techniques,
              rotating equipment, induction machine fault diagnostics, dynamic
              system modeling simulation and control, artificial intelligence,
              and energy audit.
            </p>
            <p>
              When I'm not working, I enjoy exploring the great outdoors through
              activities like hiking and fishing, as well as broadening my
              horizons through reading a variety of genres.
            </p>
            <p>
              I'm excited to apply my skills and experience to a challenging
              role in the renewable energy field. Please feel free to connect
              with me to discuss opportunities for collaboration or to learn
              more about my research. Thank you for taking the time to read my
              profile.
            </p>
          </div>

          <div id="work_experience">
            <h1>Work Experience</h1>
            <hr />
            <div className="job">
              <p id="job-title">Graduate Teaching Assistant</p>
              <div className="dates">2015-2020</div>
              <div className="org">University of Calgary, Canada</div>
              <h4>Duties:</h4>
              <ul>
                <li>Setting up laboratory equipment</li>
                <li>
                  Organize, instruct and supervise students to carry out
                  laboratory work
                </li>
                <li>Assist in teaching and conducting lab work</li>
                <li>
                  Helping students to understand the fundamentals of Control
                  System course
                </li>
              </ul>
            </div>

            <hr />

            <div className="job">
              <p id="job-title">Guest Researcher</p>
              <div className="dates">May-Oct 2018</div>
              <div className="org">Shanghai Jiao Tong University, China</div>
            </div>

            <hr />

            <div className="job">
              <p id="job-title">Guest Researcher</p>
              <div className="dates">Sep-Oct 2007</div>
              <div className="org">
                Chalmers University of Technology, Sweden
              </div>
              <h4>Title of the project:</h4>
              <p>
                Computer modeling of electromagnetic phenomena in
                non-conventional power transformers. The aim of the project was
                the development of methods for computer modeling and simulations
                of electromagnetic phenomena in non-conventional high voltage
                power transformers. The project resulted in new, efficient and
                robust algorithms, whose implementation gave information about
                physical processes and leaded to operationally more reliable and
                economically more advantageous design of transformers.
              </p>
            </div>

            <hr />

            <div className="job">
              <p id="job-title">Deputy Chief Power Electrical Engineer</p>
              <div className="dates">2007-2015</div>
              <div className="org">Zapolyarnaya Building Company, Russia</div>
              <h4>Duties:</h4>
              <ul>
                <li>
                  The organization of technically correct operation and timely
                  repair of power, electrical and environmental equipment
                </li>
                <li>
                  The organization and control of the implementation of
                  technical and organizational plans to improve the reliability
                  and operational safety of electrical supply systems
                </li>
                <li>
                  The calculation of energy needs (water, heat, electricity)
                </li>
                <li>
                  The development of plans and monitoring of their
                  implementation for saving and rational use of energy resources
                </li>
                <li>The implementation of energy audit</li>
                <li>Organizing routine servicing schedules</li>
                <li>Electrical heavy equipment installing and operating</li>
                <li>
                  Operating measuring low voltage medium voltage all kinds of
                  testers
                </li>
                <li>
                  Attending meetings, writing reports and giving presentations
                  to managers and clients
                </li>
                <li>
                  Negotiating with suppliers and purchase items at optimum cost
                </li>
                <li>
                  Making sure all electrical engineering projects are fit for
                  purpose
                </li>
                <li>
                  Reading electrical design specifications and technical
                  drawings
                </li>
                <li>
                  Preparation of electrical procedures for installation test and
                  commissioning
                </li>
                <li>
                  The performance and direct investigative assessments,
                  developing testing plans, performing equipment root cause
                  failure analysis, and supporting equipment restoration
                  activities
                </li>
              </ul>
            </div>
          </div>

          <div id="education">
            <h1>Education</h1>
            <hr />
            <div className="ed">
              <p id="ed-title">Ph.D. Candidate in Mechanical Engineering</p>
              <div className="dates">2015-Ongoing</div>
              <div className="org">University of Calgary, Canada</div>
            </div>

            <hr />

            <div className="ed">
              <p id="ed-title">Ph.D. Candidate in Electrical Engineering</p>
              <div className="dates">Sep 2004-Jun 2007</div>
              <div className="org">
                Norilsk State Industrial Institute, Russia
              </div>
            </div>

            <hr />

            <div className="ed">
              <p id="ed-title">Master's Degree in Electrical Engineering</p>
              <div className="dates">1999-2004</div>
              <div className="org">
                Norilsk State Industrial Institute, Russia
              </div>
            </div>
          </div>

          <div id="certificates">
            <h1>Certification</h1>
            <hr />
            <ul>
              <li>
                Sustainability in Energy Micro-Credential (50 hours) (geoLOGIC
                systems ltd.)
              </li>
              <li>
                Introduction to Software Development - Schulich School of
                Engineering (36 hours)
              </li>
              <li>Advanced learning algorithms</li>
              <li>Machine learning specialization</li>
              <li>
                Certificate in University Teaching and Learning for Graduate
                Students and Postdoctoral Scholars (48 hours)
              </li>
              <li>
                Supervised machine learning: regression and classification
              </li>
              <li>Azure AI Fundamentals</li>
              <li>Deep learning in Python course</li>
              <li>Importing data in Python (Part 1)</li>
              <li>Importing data in Python (Part 2)</li>
              <li>Intermediate Python for Data Science</li>
              <li>Intro to Python for Data Science course</li>
              <li>Python Data Science Toolbox</li>
              <li>
                Conducting energy audits to improve energy efficiency (72 hours)
              </li>
            </ul>
          </div>

          <div id="scholar">
            <h1>Honors & Awards</h1>
            <hr />
            <ul>
              <li>
                Globalink Research Award — China Scholarship Council for doing
                research in China, Issued by Mitacs, Jan. 2018
              </li>
              <li>
                Faculty of Graduate Studies Travel Award - International
                Student, Issued by University of Calgary, Jun. 2017
              </li>
              <li>
                International Conference Award 2017, Issued by University of
                Calgary, Jun. 2017
              </li>
              <li>
                Vibration Institute Student Sponsorship, Issued by Vibration
                Institute, Jun. 2017
              </li>
              <li>
                Canadian Machinery Vibration Association (Alberta Chapter)
                Bursary Award 2016, Issued by Canadian Machinery Vibration
                Association (Alberta Chapter), Aug. 2016
              </li>
              <li>
                Alberta Innovates Technology Futures Doctoral Scholarship,
                Issued by Alberta Innovates – Technology Futures (AITF), Sep.
                2015
              </li>
              <li>
                Dean’s Entrance Scholarship, Issued by University of Calgary,
                Sep. 2015
              </li>
            </ul>
          </div>

          <div id="languages">
            <h1>Languages</h1>
            <hr />
            <ul>
              <li>English: Fluent</li>
              <li>Russian: Native</li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default I_am;
