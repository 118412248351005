import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { client } from "../client";
import { PortableText } from "@portabletext/react";
import { Helmet } from "react-helmet-async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../styles/PostDetail.css";
import NotFound from "./NotFound";

const createUrlFromName = (name) => {
  return name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]+/g, "");
};

const PostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const query = `*[_type == "post" && slug.current == $slug][0]{
      _id,
      title,
      body,
      "seoTitle": seoTitle,
      "metaDescription": metaDescription,
      "canonicalUrl": canonicalUrl,
      mainImage{
        asset->{
          url
        },
        alt
      },
      openGraphImage{
        asset->{
          url
        },
        alt
      },
      categories[]->{title, _id},
      publishedAt,
      author->{
        name
      }
    }`;
    const params = { slug };
    client
      .fetch(query, params)
      .then((data) => {
        setPost(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching post:", err);
        setLoading(false);
      });
  }, [slug]);

  if (loading) {
    return <div className="loading">Loading...</div>; // Consider a loading spinner here
  }

  if (!post) {
    return <NotFound />;
  }

  const postUrl = window.location.href;

  return (
    <div className="main-container">
      <Helmet>
        <title>{post.seoTitle || post.title}</title>
        <meta name="description" content={post.metaDescription} />
        <meta property="og:title" content={post.seoTitle || post.title} />
        <meta property="og:description" content={post.metaDescription} />
        <meta
          property="og:image"
          content={post.openGraphImage?.asset?.url || post.mainImage.asset.url}
        />
        <meta
          property="og:image:alt"
          content={post.openGraphImage?.alt || post.mainImage.alt}
        />
        <link rel="canonical" href={post.canonicalUrl || postUrl} />
      </Helmet>
      <div className="post-detail">
        <h1>{post.title}</h1>
        {post.mainImage && (
          <img
            src={post.mainImage.asset.url}
            alt={post.mainImage.alt || "Post image"}
          />
        )}
        <div className="post-category-detail">
          {post.categories?.map((category, index) => (
            <React.Fragment key={index}>
              <Link
                to={`/category/${createUrlFromName(category.title)}`}
                className="post-category-link"
              >
                {category.title}
              </Link>
              {index < post.categories.length - 1 ? ", " : ""}
            </React.Fragment>
          ))}
        </div>
        <div className="post-date-detail">
          {new Date(post.publishedAt).toLocaleDateString()}
        </div>
        <div className="post-body-detail">
          <PortableText value={post.body} />
        </div>
        <div className="share-icons">
          <span>Share this post:</span>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href={`https://twitter.com/intent/tweet?url=${postUrl}&text=${post.title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${postUrl}&title=${post.title}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a
            href={`mailto:?subject=${post.title}&body=Check out this article: ${postUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
