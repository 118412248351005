import React, { useEffect, useState } from "react";
import PostCard from "./PostCard";
import { client } from "../client";

const PostList = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const postsPerPage = 5; // Number of posts per page

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const query = `*[_type == "post"] | order(publishedAt desc){ // Order by published date, most recent first
          _id,
          title,
          slug,
          body,
          mainImage{
            asset->{
              url
            },
            alt
          },
          categories[]->{title, _id},
          publishedAt,
          author->{
            name
          }
        }`;
        const data = await client.fetch(query);
        setPosts(data);
      } catch (err) {
        console.error("Error fetching posts:", err);
      }
    };

    fetchPosts();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="post-list">
      {currentPosts.map((post) => (
        <PostCard
          key={post._id}
          slug={post.slug.current}
          title={post.title}
          description={post.body}
          image={post.mainImage?.asset?.url}
          alt={post.mainImage?.alt || "Post image"}
          categories={post.categories} // Pass the categories here
          post_date={new Date(post.publishedAt).toLocaleDateString()}
          author={post.author?.name || "Unknown Author"}
        />
      ))}

      {/* Pagination */}
      <div className="pagination">
        {Array.from(
          { length: Math.ceil(posts.length / postsPerPage) },
          (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={i + 1 === currentPage ? "active" : ""}
            >
              {i + 1}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default PostList;
