import React from "react";
import { Helmet } from "react-helmet";
import "../styles/I_travel.css";

function I_travel() {
  const visitedCounties = [
    "Sweden",
    "China",
    "USA",
    "Russia",
    "Ukraine",
    "Laos",
    "Bolivia",
    "Peru",
    "Equador",
    "Cuba",
    "Thailand",
    "Malasia",
    "Vietnam",
    "Mexico",
    "Canada",
    "Cambodia",
    "Finland",
  ];
  const wantToVisitCounties = ["India", "Colombia", "Nepal", "Brasil"];

  return (
    <>
      <Helmet>
        <title>I travel</title>
        <meta
          name="description"
          content="Countries I have visited, and I want to visit"
        />
        <meta name="keywords" content="travel, countries, adventures" />
        <meta property="og:title" content="I travel" />
        <meta
          property="og:description"
          content="Countries I have visited, and I plan to visit"
        />
        <meta property="og:image" content="URL_to_image_for_social_media.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://iuriistorozhenko.com/i_travel" />
      </Helmet>

      <div className="main-container">
        <main id="main_info">
          <h1>
            A collection of countries I have visited <br /> and those I plan to
            visit.
          </h1>
        </main>
        <Section
          id="visited"
          title="Visited Countries"
          items={visitedCounties}
        />
        <Section
          id="want-to-visit"
          title="Countries to Visit"
          items={wantToVisitCounties}
        />
      </div>
    </>
  );
}

const Section = ({ id, title, items }) => {
  return (
    <section id={id} className="section-container">
      <h2>{title}</h2>
      <ul className="county-list">
        {items.map((item, index) => (
          <li key={index} className="county-card">
            {item}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default I_travel;
