import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import "../styles/Search.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // FontAwesome for icons

const Search = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const performSearch = async (searchTerm) => {
      if (!searchTerm) {
        setResults([]);
        return;
      }

      try {
        const searchQuery = `*[_type == "post" && title match $term]{
          _id,
          title,
          slug, // Ensure that slug is included in the query
          body[0..300], // Limit the body content to the first 300 characters
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          categories[0]->{title},
          publishedAt,
          author->{
            name
          }
        }`;

        const params = { term: `${searchTerm}*` };
        const data = await client.fetch(searchQuery, params);

        setResults(data);
      } catch (err) {
        console.error("Search error:", err);
        setResults([]);
      }
    };

    const delayDebounceFn = setTimeout(() => {
      performSearch(query);
    }, 300); // Delay of 300ms for debouncing

    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const handleClearSearch = () => {
    setQuery("");
    setResults([]);
  };

  return (
    <div className="search-component">
      <div className="search-input-container">
        <i className="fas fa-search search-icon"></i>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search posts..."
          className="search-input"
        />
        {query && (
          <i
            className="fas fa-times close-icon"
            onClick={handleClearSearch}
          ></i>
        )}
      </div>
      <ul className="search-results">
        {results.length > 0 &&
          query &&
          results.map((result) => (
            <li key={result._id} className="search-result-item">
              {result.mainImage?.asset?.url && (
                <img
                  src={result.mainImage.asset.url}
                  alt={result.mainImage.alt || "Post image"}
                  className="search-result-image"
                />
              )}
              <div className="search-result-text">
                <h3>
                  <Link
                    to={`/post/${result.slug.current}`} // Use slug instead of _id for SEO-friendly URLs
                    className="search-result-link"
                  >
                    {result.title}
                  </Link>
                </h3>
                <p className="line-clamp">
                  {result.body
                    ? result.body[0]?.children[0]?.text
                    : "No content available."}
                </p>
                <small>
                  {new Date(result.publishedAt).toLocaleDateString()} -{" "}
                  {result.author?.name}
                </small>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Search;
