import React from "react";
import "../styles/BookCard.css";

const BookCard = ({ title, description, image, alt, year, author }) => {
  return (
    <div className="book">
      <img src={image} alt={alt} className="book-image" />
      <div className="book-info">
        <h2>{title}</h2>
        <p className="book-author">by {author}</p>{" "}
        {/* Display the author's name */}
        <p className="book-description">{description}</p>
      </div>
    </div>
  );
};

export default BookCard;
