import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async"; // Import Helmet and HelmetProvider
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Iam from "./pages/I_am";
import Ido from "./pages/I_do";
import Itravel from "./pages/I_travel";
import Iread from "./pages/I_read";
import Contact from "./pages/Contact";
import PostList from "./components/PostList";
import PostDetail from "./components/PostDetail";
import CategoryPosts from "./components/CategoryPosts";
import NotFound from "./components/NotFound";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>Default Site Title</title>
          <meta
            name="description"
            content="Default description for the website."
          />
        </Helmet>
        <div className="App-container">
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="i_am" element={<Iam />} />
              <Route path="i_do" element={<Ido />} />
              <Route path="i_travel" element={<Itravel />} />
              <Route path="i_read" element={<Iread />} />
              <Route path="contact" element={<Contact />} />
              <Route path="posts" element={<PostList />} />
              <Route path="post/:slug" element={<PostDetail />} />
              <Route path="category/:name" element={<CategoryPosts />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
