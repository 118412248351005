import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/NotFound.css"; // Make sure to create this CSS file

const NotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="main-container">
      <div className="not-found-container">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-message">
          Oops! The page you're looking for doesn't exist.
        </p>
        <p className="not-found-redirect">Redirecting to the homepage...</p>
      </div>
    </div>
  );
};

export default NotFound;
