import React, { useEffect, useState } from "react";
import { client } from "../client"; // Ensure correct path to your Sanity client
import BookCard from "./BookCard"; // Ensure correct path to your BookCard component
import "../styles/BookList.css"; // Include your CSS file for styling

const BookList = () => {
  const [books, setBooks] = useState({});

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const query = `*[_type == "books"]{
          _id,
          title,
          description,
          image{
            asset->{
              url
            },
            alt
          },
          year,
          author // Fetching author directly as a string
        } | order(year desc, _createdAt desc)`; // Order by year and creation date descending

        const data = await client.fetch(query);

        // Group books by year
        const groupedBooks = data.reduce((acc, book) => {
          if (!acc[book.year]) acc[book.year] = [];
          acc[book.year].push(book);
          return acc;
        }, {});

        setBooks(groupedBooks);
      } catch (err) {
        console.error("Error fetching books:", err);
      }
    };

    fetchBooks();
  }, []);

  return (
    <div className="book-list">
      {Object.keys(books)
        .sort((a, b) => b - a) // Sort years in descending order
        .map((year) => (
          <div key={year} className="year-group">
            <h2 className="year-title">{year}</h2>
            <div className="books-container">
              {books[year].slice(0, 5).map((book) => (
                <BookCard
                  key={book._id}
                  title={book.title}
                  description={book.description}
                  image={book.image?.asset?.url}
                  alt={book.image?.alt || "Book image"}
                  year={book.year}
                  author={book.author || "Unknown Author"}
                />
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default BookList;
