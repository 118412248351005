import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../client";
import "../styles/Categories.css";

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const query = '*[_type == "category"]';
      const results = await client.fetch(query);
      setCategories(results);
    };

    fetchCategories();
  }, []);

  return (
    <div className="main-container">
      <div className="categories">
        <h2>Categories</h2>
        <ul>
          {categories.map((category) => (
            <li key={category._id}>
              <Link to={`/category/${encodeURIComponent(category.title)}`}>
                {category.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Categories;
