import { Helmet } from "react-helmet";
import "../styles/I_do.css";
import React, { useState } from "react";

const Post = ({ title, content, date }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  // Split content into paragraphs
  const paragraphs = content.split("\n\n");

  return (
    <div className="main-post">
      <h1>{title}</h1>
      <p className="post-date">{date}</p> {/* Display the post date */}
      {isExpanded ? (
        paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>)
      ) : (
        <p>{`${content.substring(0, 200)}...`}</p>
      )}
      <button className="read-more-button" onClick={toggleExpand}>
        {isExpanded ? "Read Less" : "Read More"}
      </button>
      <hr className="post-divider" /> {/* Divider line after each post */}
    </div>
  );
};

function I_do() {
  const postContent = `This project involves the development of a comprehensive multi-physics wind turbine mathematical model, designed to analyze the impact of mechanical faults on the current signals of induction generators. The model integrates aerodynamic, mechanical, electrical, and control subsystems to offer a holistic view of turbine operation under various conditions.

Aerodynamic and structural components are modeled using NREL-certified OpenFAST code, renowned for its accurate wind turbine aerodynamics simulations. Realistic numerical wind fields are generated using the TurbSim code. Matlab/Simulink is utilized for modeling the mechanical and electrical systems.

A significant advancement in this project is the replacement of the simplified two-mass mechanical drivetrain model, previously employed in OpenFAST, with a detailed wind turbine drivetrain model. This new model incorporates gear tooth contact elasticity and backlash nonlinearities, offering a more precise representation of drivetrain dynamics.

The model has undergone rigorous validation against published research and established physics principles to ensure accuracy at both the component and system levels. It includes non-linear interactions between subsystems and can simulate major mechanical faults, such as aerodynamic rotor mass imbalances and gearbox gear crack faults, providing insights into their effects on wind turbine performance, particularly concerning the induction generator’s current signals.`;

  const postDate = "August 15, 2024"; // Example date

  return (
    <>
      <Helmet>
        ‍<title>I do</title>
        <meta
          name="description"
          content="Projects I am working on at the present moment"
        />
        <meta name="keywords" content="project, science, research" />
        <meta property="og:title" content="Title for social media" />
        <meta
          property="og:description"
          content="Projects I am working on at the present moment"
        />
        <meta property="og:image" content="URL_to_image_for_social_media.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://iuriistorozhenko.com/i_do" />
      </Helmet>
      <div className="main-container">
        <main id="main_info">
          <h1>
            "The best way to predict the future is to create it."
            <br />
            Peter Drucker.
          </h1>
        </main>

        <Post
          title="A Multi-Physics Approach to Modeling Wind Turbine Dynamics: Analyzing the Impact of Mechanical Faults on Induction Generator Current Signals"
          content={postContent}
          date={postDate}
        />
      </div>
    </>
  );
}

export default I_do;
