import React from "react";
import { Helmet } from "react-helmet";
import "../styles/I_read.css";
import BookList from "../components/BookList";

function I_read() {
  return (
    <>
      <Helmet>
        <title>I read</title>
        <meta
          name="description"
          content="Books I have read and like the most"
        />
        <meta name="keywords" content="books, reading, books recommendation" />
        <meta property="og:title" content="Title for social media" />
        <meta property="og:description" content="Books recommendations" />
        <meta property="og:image" content="URL_to_image_for_social_media.png" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://iuriistorozhenko.com/i_read" />
      </Helmet>
      <div className="main-container">
        <main id="main_info">
          <h1>
            I read what defines my curiosities <br />
            at the moment.
          </h1>
        </main>

        <div id="reading_section" className="container">
          <h1>A Year in 5 Books</h1>
          <h2>
            Five books from each year that define that year, that were the most
            influential, that I learned the most from
          </h2>
        </div>
        <BookList />
      </div>
    </>
  );
}

export default I_read;
