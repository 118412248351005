import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { client } from "../client";
import PostCard from "./PostCard";
import "../styles/CategoryPosts.css";

const createUrlFromName = (name) => {
  return name
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]+/g, "");
};

const CategoryPosts = () => {
  const { name } = useParams(); // Use the category name from the URL
  const [posts, setPosts] = useState([]);
  const [categoryName, setCategoryName] = useState(""); // State to hold the category name
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchCategoryAndPosts = async () => {
      try {
        setLoading(true); // Start loading
        // Fetch the category details based on the name
        const categoryQuery = `*[_type == "category" && title match $name][0]{
          _id,
          title
        }`;
        const categoryData = await client.fetch(categoryQuery, { name });

        if (categoryData) {
          setCategoryName(categoryData.title); // Set the category name

          // Fetch posts in this category using the category ID
          const postsQuery = `*[_type == "post" && $id in categories[]->_id] | order(publishedAt desc) {
            _id,
            title,
            slug,
            body,
            mainImage{
              asset->{
                url
              },
              alt
            },
            categories[]->{title, _id},
            publishedAt,
            author->{
              name
            }
          }`;
          const postsData = await client.fetch(postsQuery, {
            id: categoryData._id,
          });
          setPosts(postsData);
        } else {
          console.error("Category not found");
        }
      } catch (err) {
        console.error("Error fetching category and posts:", err);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchCategoryAndPosts();
  }, [name]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-container">
      <div className="category-post-list">
        <h2>
          Posts in <span className="category-name">{categoryName}</span>
        </h2>
        {posts.length > 0 ? (
          posts.map((post) => (
            <PostCard
              key={post._id}
              slug={post.slug.current}
              title={post.title}
              description={post.body}
              image={post.mainImage?.asset?.url}
              alt={post.mainImage?.alt || "Post image"}
              categories={post.categories.map((category) => ({
                title: category.title,
                _id: category._id,
                url: createUrlFromName(category.title),
              }))}
              post_date={new Date(post.publishedAt).toLocaleDateString()}
              author={post.author?.name || "Unknown Author"}
            />
          ))
        ) : (
          <p>No posts found in this category.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryPosts;
